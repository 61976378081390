"use client";

import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import ContactBar from "./components/ContactBar";
import DesktopMenu from "./components/DesktopMenu";
import MobileMenu from "./components/MobileMenu";
import styles from "./css/HeaderContainer.module.scss";
import DPIcon from "../DPIcon/DPIcon";

export default function HeaderContainer({ content, mobileMenu, animated, transparent, breakPointLow, breakPointHigh }: {
  readonly content: Record<string,any>,
  readonly mobileMenu: Record<string,any>,
  readonly animated?: boolean,
  readonly transparent?: boolean,
  readonly breakPointLow?: number,
  readonly breakPointHigh?: number,
}) {
  const data = { ...content };

  const header: RefObject<HTMLElement> = useRef(null);
  const stickyRef: RefObject<HTMLDivElement> = useRef(null);
  const stickyPlaceholderRef: RefObject<HTMLDivElement> = useRef(null);

  const [ hasStickyFooter, setHasStickyFooter ] = useState(false);

  const [ anim, setAnim ] = useState(styles['top-bar'] + ' ' + (animated ? styles['hidden-menu'] : ''));
  const [ trans, setTrans ] = useState(transparent ?? false);
  const [ menuState, setMenuState ] = useState(false);

  const menuToggle = (forceState?: boolean) => {
    setMenuState((current: boolean) => {
      if(forceState !== undefined) return forceState;
      
      return !current;
    });
  };

  const [ isStickyActive, setIsStickyActive ] = useState<boolean>(true);

  // eslint-disable-next-line no-unused-vars
  const applySticky = useCallback((closed?: boolean) => {
    if(window && window.innerWidth > 767) {
      closed = true;
    }
    
    if(closed === undefined) closed = !isStickyActive;
    
    if(closed) {
      stickyPlaceholderRef.current?.style.setProperty('height', `0px`);
      stickyRef.current?.style.setProperty('opacity', "0");
      setTimeout(() => {
        stickyRef.current?.style.setProperty('display', "none");
      }, 300);
    } else {
      stickyPlaceholderRef.current?.style.setProperty('height', `${stickyRef.current?.offsetHeight}px`);
      stickyRef.current?.style.setProperty('display', "block");
      setTimeout(() => {
        stickyRef.current?.style.setProperty('opacity', "1");
      }, 10);
    }

    setIsStickyActive(!closed);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isStickyActive ]);
  
  useEffect(() => {
    const bpl = breakPointLow ?? 180;
    const bph = Math.max(bpl + 1, (breakPointHigh ?? 360));

    const onScroll = () => {
      const st = window?.document?.documentElement?.scrollTop;
      
      if(transparent) {
        if(st > bph) setTrans(false);
        if(st < bpl) setTrans(true);
      }
      
      if(animated) {
        if(
          st > bph
          && !header.current?.classList.contains(styles['visible-menu'])
        ) setAnim(styles['top-bar'] + ' ' + styles['visible-menu'] + ' ' + styles.animate);
        if(
          st < bpl
          && !header.current?.classList.contains(styles['hidden-menu'])
        ) setAnim(styles['top-bar'] + ' ' + styles['hidden-menu'] + ' ' + styles.animate);
      }

      /* If there is a Sticky Footer */
      const isMobile = window?.innerWidth < 1024;
      const stickyFooter = document?.querySelector('[data-footer-scroll-break-point]') as HTMLElement;
      const scrollBreakPoint = stickyFooter ? parseInt(stickyFooter?.dataset?.footerScrollBreakPoint ?? '0') : 0;
      
      setHasStickyFooter(stickyFooter ? true : false);
      
      const hgt = isMobile && scrollBreakPoint ? '0px' : '-44px';
      if(scrollBreakPoint && window?.scrollY > scrollBreakPoint) {
        header.current?.style.setProperty('transform', `translateY(${hgt})`);
      } else {
        header.current?.style.setProperty('transform', `translateY(0px)`);
      }

      applySticky();
    };
    
    onScroll();

    window?.addEventListener('scroll', onScroll);
    window?.addEventListener('resize', onScroll);

    return () => {
      window?.removeEventListener('scroll', onScroll);
      window?.removeEventListener('resize', onScroll);
    };
  }, [
    animated,
    transparent,
    breakPointHigh,
    breakPointLow,
    applySticky
  ]);
  
  return (
    <>
      {/*
      Sticky / fixed layer at the top of the page causes scroll behaviour to mess up.
      Placing a dom element to the top prevents that.
      Not a tasty solution but its the least expensive one...
      */}
      <div className="sticky-fix"></div>

      <div ref={stickyRef} className={`${styles.stickyBar} bg-lava`}>
        {
          isStickyActive && (
            <>
              <div className="container">
                <div className="text-white text-center py-0 px-2 text-sm md:text-base">
                  <span className="block md:hidden">
                    Erken kayıt avantajları için <strong>hemen bizi arayın!</strong>
                  </span>
                  <span className="hidden md:block">
                    Erken kayıt avantajları için <strong>hemen bizi arayın!</strong>
                  </span>
                </div>
              </div>
              <div className="absolute z-10 right-2 md:right-4 top-0 bottom-0 flex items-center">
                <DPIcon icon="close" className="text-white cursor-pointer" onClick={() => applySticky(true)} />
              </div>
            </>
          )
        }
      </div>
      <header ref={header} className={`${styles.header} ${trans ? styles.trans : ''} ${anim} ${hasStickyFooter ? 'transition-all duration-300' : ''}`}>
        <div ref={stickyPlaceholderRef} className={`${styles.stickyPlaceholder}`}></div>
        <ContactBar transparent={trans} menuToggle={menuToggle} content={data} />
        <DesktopMenu transparent={trans} logo={data?.logo ?? {}} menu={data?.menu ?? []} />
      </header>
      <MobileMenu menuState={menuState} data={mobileMenu} menuToggle={menuToggle} />
    </>
  );
}
